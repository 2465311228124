<template>
  <RealisasiVolumePasokanForm mode="Ubah" module="Realisasi Volume Pasokan"> </RealisasiVolumePasokanForm>
</template>

<script>
import RealisasiVolumePasokanForm from './form';

const RealisasiVolumePasokanUpdate = {
  name: 'RealisasiVolumePasokanUpdate',
  components: { RealisasiVolumePasokanForm },
};

export default RealisasiVolumePasokanUpdate;
</script>
